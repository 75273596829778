import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Icon } from '@dayinsure/components';

import QRCode from '../../../../assets/appQRCode.svg';
import { APPLE_APP_STORE_LINK, GOOGLE_PLAY_STORE_LINK } from '../../../../constants/apps';
import {
  appleAppstore1x,
  appleAppstore2x,
  appleAppstore3x,
  googleAppstore1x,
  googleAppstore2x,
  googleAppstore3x,
} from '../../../../assets/appstore';
import generateSrcSet from '../../../../helpers/images/generateSrcSet';

type AdvertProps = {
  onClose(): void;
};

const Advert = ({ onClose }: AdvertProps) => {
  const { t } = useTranslation('quotes');

  return (
    <Card classNames="my-4 sm:my-8 lg:my-12 relative text-center">
      <button
        className="absolute top-4 right-4 z-10 md:top-6 md:right-6"
        onClick={onClose}
        type="button"
        id="advert-close-button"
        aria-label="close advert button"
      >
        <Icon name="cross" className="pointer-events-none text-main-content-1" />
      </button>

      <div>
        <h2 className="text-lg text-main-content-1">{t('vehicle.advert.title')}</h2>
        <p className="px-4 mt-2 sm:px-0 lg:px-8 text-main-content-1">
          {t('vehicle.advert.text')}
        </p>

        <div className="flex gap-6 justify-center my-4 sm:my-6">
          <div className="hidden lg:block">
            <img
              src={QRCode}
              width={120}
              height={120}
              alt={t('vehicle.advert.qr_code_alt')}
            />
            <p className="mt-1 font-lato text-xs opacity-60 text-main-content-1">
              {t('vehicle.advert.qr_code_hint')}
            </p>
          </div>

          <div className="flex gap-6 justify-center items-start lg:flex-col lg:pb-5">
            <a
              id="apple-app-store-badge"
              href={APPLE_APP_STORE_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={appleAppstore1x}
                srcSet={generateSrcSet(appleAppstore2x, appleAppstore2x, appleAppstore3x)}
                alt={t('vehicle.advert.apple_app_store')}
                className="w-[120px] h-[40px]"
              />
            </a>
            <a
              id="google-play-store-badge"
              href={GOOGLE_PLAY_STORE_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={googleAppstore1x}
                srcSet={generateSrcSet(
                  googleAppstore1x,
                  googleAppstore2x,
                  googleAppstore3x
                )}
                alt={t('vehicle.advert.google_play_store')}
                className="w-[135px] h-[40px]"
              />
            </a>
          </div>
        </div>
        <p className="px-4 font-lato text-sm opacity-60 sm:px-0 text-main-content-1">
          {t('vehicle.advert.hint')}
        </p>
      </div>
    </Card>
  );
};

export default Advert;

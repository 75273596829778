import clsx from 'clsx';
import { forwardRef, useState } from 'react';
import Icon from '../../DataDisplay/Icon/Icon';
import { onEnterKey } from '../../../types/events';

/** This is just a styled input with some mandatory props */
export type PasswordFieldProps = {
  /** ID of the input field. Used to match with label */
  id: string;
  /** Name of the field. Used to track within formik  */
  name: string;
  /** aria label to be applied to the button which opens the tooltip, for screenreaders */
  ariaLabel: string;
  /** Placeholder to display when the field is empty */
  placeholder: string;
  /** The current value of the field */
  value?: string;
  /** The event which is emitted when the value is changed */
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /** Disabled state */
  disabled?: boolean;
  /** Error state */
  error?: boolean;
  /** Variant */
  variant?: 'default' | 'dialog';
  /** Id used for testing */
  testId?: string;
};

const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  (
    {
      id,
      name,
      ariaLabel,
      placeholder,
      value,
      onChange,
      disabled,
      error,
      variant = 'default',
      testId,
      ...rest
    },
    ref
  ): JSX.Element => {
    const [passwordShown, setPasswordShown] = useState(false);
    const showPassword = () => {
      setPasswordShown(!passwordShown);
    };

    return (
      <div className="flex relative items-center">
        <input
          id={id}
          data-testid={testId}
          name={name}
          type={passwordShown ? 'text' : 'password'}
          className={clsx(
            'bg-transparent rounded-lg border-2 focus:outline-none transition-colors  field-base',
            { 'opacity-40': disabled },
            {
              'border-main-content-3': !error && variant === 'default',
              'border-popup-selector-border': !error && variant === 'dialog',
              'border-error-border': error,
            },
            {
              'focus:border-main-content-1 text-main-content-1 placeholder:text-main-content-2':
                variant === 'default',
              'focus:border-popup-content-2 text-popup-content-1 placeholder:text-popup-content-2':
                variant === 'dialog',
            }
          )}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          ref={ref}
          aria-invalid={error}
          {...rest}
        />
        <button
          className={clsx('flex absolute right-4 z-10 p-2', { 'opacity-40': disabled })}
          type="button"
          aria-label={ariaLabel}
          onKeyDown={onEnterKey(showPassword)}
          onMouseDown={showPassword}
          disabled={disabled}
        >
          <Icon
            className={clsx({
              'text-cta-secondary-text': variant === 'default',
              'text-popup-secondary-cta-text': variant === 'dialog',
            })}
            name={passwordShown ? 'password-hide' : 'password'}
          />
        </button>
      </div>
    );
  }
);

export default PasswordField;

import { Outlet, Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import { AuthProvider } from 'react-oidc-context';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { ReferrerProvider } from './contexts/ReferrerContext/ReferrerContext';
import NavigateWithReferrer from './components/Common/Links/NavigateWithReferrer';
import useTheme from './hooks/useTheme';
import ScrollToTop from './components/Common/ScrollToTop/ScrollToTop';
import LazyLoaderSpinner from './components/Common/LazyLoaderSpinner/LazyLoaderSpinner';
import { LazyPreloadProvider } from './contexts/LazyPreloadContext/LazyPreloadContext';
import { lazyWithPreload } from './helpers/lazyLoading/lazyLoading';
import { PersonProvider } from './contexts/PersonContext/PersonContext';
import AuthEventsHandler from './components/Auth/AuthEventsHandler/AuthEventsHandler';
import ProtectedRoute from './components/Auth/ProtectedRoute/ProtectedRoute';
import QuotePolicyInfo from './components/Quote/QuotePolicyInfo/QuotePolicyInfo';
import GlobalErrorWrapper from './components/Common/GlobalErrorWrapper/GlobalErrorWrapper';
import QuoteJourneyGuard from './components/Quote/QuoteJourney/QuoteJourneyGuard';
import PaymentProcessing from './components/Quote/Pay/PaymentProcessing/PaymentProcessing';
import PurchaseComplete from './components/Quote/PurchaseComplete/PurchaseComplete';
import Help from './components/Account/Help/Help';
import Retail from './components/Retail/Retail';
import useAuthConfig from './hooks/useAuthConfig';
import useReferrerFetcher from './hooks/useReferrerFetcher';
import { DocumentProvider } from './contexts/DocumentContext/DocumentContext';
import Logout from './components/Auth/Logout/Logout';
import QuoteJourney from './components/Quote/QuoteJourney/QuoteJourney';
import RegistrationSearch from './components/Quote/Vehicle/RegistrationSearch/RegistrationSearch';
import PageNotFound from './components/PageNotFound/PageNotFound';
import useCookieConsent from './hooks/useCookieConsent';

const Dashboard = React.lazy(() => import('./components/Dashboard/Dashboard'));
const SignUpRoutes = React.lazy(() => import('./routes/SignUpRoutes'));
const ForgotPasswordRoutes = React.lazy(() => import('./routes/ForgotPasswordRoutes'));
const AccountRoutes = React.lazy(() => import('./routes/AccountRoutes'));
const PoliciesRoutes = React.lazy(() => import('./routes/PoliciesRoutes'));
const [ManualVehicleSearch, preloadManualVehicleSearch] = lazyWithPreload(
  () => import('./components/Quote/Vehicle/ManualSearchForm/ManualVehicleSearch')
);
const [DriverDetails, preloadDriverDetails] = lazyWithPreload(
  () => import('./components/Quote/Driver/DriverDetailsForm/DriverDetails')
);
const [ContactDetails, preloadContactDetails] = lazyWithPreload(
  () => import('./components/Quote/ContactDetails/ContactDetails')
);
const [LearnerDriver, preloadLearnerDriver] = lazyWithPreload(
  () => import('./components/Quote/Driver/LearnerDriverForm/LearnerDriver')
);
const [CoverLength, preloadCoverLength] = lazyWithPreload(
  () => import('./components/Quote/CoverLength/CoverLength')
);
const [CoverAddons, preloadCoverAddons] = lazyWithPreload(
  () => import('./components/Quote/CoverAddons/CoverAddons')
);
const [AcceptanceStatements, preloadAcceptanceStatements] = lazyWithPreload(
  () => import('./components/Quote/AcceptanceStatements/AcceptanceStatements')
);
const [Review, preloadReview] = lazyWithPreload(
  () => import('./components/Quote/Review/Review')
);
const [Pay, preloadPay] = lazyWithPreload(() => import('./components/Quote/Pay/Pay'));
const [, preloadCheckout] = lazyWithPreload(
  () => import('./components/Common/Checkout/Checkout')
);

const preloadContext = {
  preloadManualVehicleSearch,
  preloadDriverDetails,
  preloadContactDetails,
  preloadLearnerDriver,
  preloadCoverLength,
  preloadCoverAddons,
  preloadAcceptanceStatements,
  preloadReview,
  preloadPay,
  preloadCheckout,
};

const App = () => {
  const referrer = useReferrerFetcher();
  const authConfig = useAuthConfig(referrer);

  useTheme(referrer);

  useCookieConsent();

  return (
    <GlobalErrorWrapper>
      <LazyPreloadProvider value={preloadContext}>
        <ReferrerProvider value={referrer}>
          <PersonProvider>
            <DocumentProvider>
              <AuthProvider {...authConfig}>
                <AuthEventsHandler>
                  <div
                    id="scroll-container"
                    className="flex overflow-auto fixed inset-y-0 flex-col w-screen bg-gradient-to-br text-main-content-1 from-page-background-from to-page-background-to"
                  >
                    <Header />
                    <main className="relative">
                      <ScrollToTop>
                        <Routes>
                          <Route
                            path="/"
                            element={
                              <NavigateWithReferrer to={{ pathname: '/quote' }} replace />
                            }
                          />

                          {/* Auth Routes */}
                          <Route path="/login">
                            <Route path="redirect" element={<div />} />
                          </Route>
                          <Route path="/logout" element={<Logout />} />
                          <Route
                            path="/forgotten-password/*"
                            element={
                              <Suspense fallback={<LazyLoaderSpinner />}>
                                <ForgotPasswordRoutes />
                              </Suspense>
                            }
                          />
                          <Route
                            path="/sign-up/*"
                            element={
                              <Suspense fallback={<LazyLoaderSpinner />}>
                                <SignUpRoutes />
                              </Suspense>
                            }
                          />

                          {/* Dashboard Routes */}
                          <Route
                            path="/dashboard"
                            element={
                              <ProtectedRoute>
                                <Suspense fallback={<LazyLoaderSpinner />}>
                                  <Dashboard />
                                </Suspense>
                              </ProtectedRoute>
                            }
                          />

                          {/* Policies Routes */}
                          <Route
                            path="/policies/*"
                            element={
                              <Suspense fallback={<LazyLoaderSpinner />}>
                                <PoliciesRoutes />
                              </Suspense>
                            }
                          />

                          <Route
                            path="/help"
                            element={
                              <ProtectedRoute>
                                <Help />
                              </ProtectedRoute>
                            }
                          />

                          {/* Account Routes */}
                          <Route
                            path="/account/*"
                            element={
                              <ProtectedRoute>
                                <Suspense fallback={<LazyLoaderSpinner />}>
                                  <AccountRoutes />
                                </Suspense>
                              </ProtectedRoute>
                            }
                          />

                          {/* Quote Journey */}
                          <Route path="/quote" element={<QuoteJourney />}>
                            <Route
                              index
                              element={
                                <NavigateWithReferrer
                                  to={{ pathname: 'registration-search' }}
                                  replace
                                />
                              }
                            />
                            <Route
                              path="registration-search"
                              element={<RegistrationSearch />}
                            />
                            <Route
                              path="manual-vehicle-entry"
                              element={
                                <Suspense fallback={<LazyLoaderSpinner />}>
                                  <QuoteJourneyGuard redirect="/quote">
                                    <ManualVehicleSearch />
                                  </QuoteJourneyGuard>
                                </Suspense>
                              }
                            />
                            <Route
                              path="driver-details"
                              element={
                                <Suspense fallback={<LazyLoaderSpinner />}>
                                  <QuoteJourneyGuard redirect="/quote">
                                    <DriverDetails />
                                  </QuoteJourneyGuard>
                                </Suspense>
                              }
                            />
                            <Route
                              path="contact-details"
                              element={
                                <Suspense fallback={<LazyLoaderSpinner />}>
                                  <QuoteJourneyGuard redirect="/quote">
                                    <ContactDetails />
                                  </QuoteJourneyGuard>
                                </Suspense>
                              }
                            />
                            <Route
                              path="learner-driver"
                              element={
                                <Suspense fallback={<LazyLoaderSpinner />}>
                                  <QuoteJourneyGuard redirect="/quote">
                                    <LearnerDriver />
                                  </QuoteJourneyGuard>
                                </Suspense>
                              }
                            />
                            <Route path=":quoteId">
                              <Route
                                index
                                element={
                                  <NavigateWithReferrer
                                    to={{ pathname: 'cover-length' }}
                                    replace
                                  />
                                }
                              />
                              <Route
                                path="cover-length"
                                element={
                                  <Suspense fallback={<LazyLoaderSpinner />}>
                                    <CoverLength />
                                  </Suspense>
                                }
                              />
                              <Route
                                path="addons"
                                element={
                                  <Suspense fallback={<LazyLoaderSpinner />}>
                                    <QuoteJourneyGuard redirect="../cover-length">
                                      <CoverAddons />
                                    </QuoteJourneyGuard>
                                  </Suspense>
                                }
                              />
                              <Route
                                path="acceptance"
                                element={
                                  <Suspense fallback={<LazyLoaderSpinner />}>
                                    <QuoteJourneyGuard redirect="../cover-length">
                                      <AcceptanceStatements />
                                    </QuoteJourneyGuard>
                                  </Suspense>
                                }
                              />
                              <Route
                                path="review"
                                element={
                                  <Suspense fallback={<LazyLoaderSpinner />}>
                                    <QuoteJourneyGuard redirect="../cover-length">
                                      <Review />
                                    </QuoteJourneyGuard>
                                  </Suspense>
                                }
                              />
                              <Route
                                path="pay"
                                element={
                                  <Suspense fallback={<LazyLoaderSpinner />}>
                                    <QuoteJourneyGuard redirect="../cover-length">
                                      <Pay />
                                    </QuoteJourneyGuard>
                                  </Suspense>
                                }
                              />
                            </Route>
                          </Route>

                          <Route path="/policy" element={<Outlet />}>
                            <Route path="processing" element={<PaymentProcessing />} />
                            <Route path=":policyId">
                              <Route path="success" element={<PurchaseComplete />} />
                              <Route path="info" element={<QuotePolicyInfo />} />
                            </Route>
                          </Route>

                          <Route path="/retail" element={<Retail />} />

                          {/* 404 */}
                          <Route path="*" element={<PageNotFound />} />
                        </Routes>
                      </ScrollToTop>
                    </main>
                    <Footer />
                  </div>
                </AuthEventsHandler>
              </AuthProvider>
            </DocumentProvider>
          </PersonProvider>
        </ReferrerProvider>
      </LazyPreloadProvider>
    </GlobalErrorWrapper>
  );
};

export default App;

import { Button, Card, FormattedPrice, Icon } from '@dayinsure/components';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { PolicyState, GetPolicyResponseIAPIResult, AddOnType } from '../../../api';
import PolicyDocumentsList from './PolicyDocumentsList';
import useNavigateWithReferrer from '../../../hooks/useNavigateWithReferrer';
import ClaimsNumberCard from '../../Common/PolicyContact/ClaimNumberCard';
import BreakdownNumberCard from '../../Common/PolicyContact/BreakdownNumberCard';
import getAddonTitle from '../../Quote/CoverAddons/Addons/getAddonTitle';
import { getDiscount, getDiscountValue } from '../../../helpers/discount/discount';
import { PolicyStateProgressBar } from './PolicyStateProgressBar';

type PolicyInfoComponentProps = {
  policyId?: string;
  policyData?: GetPolicyResponseIAPIResult | undefined;
  canExtend?: boolean;
};

const PolicyInfoComponent = ({
  policyId,
  policyData,
  canExtend,
}: PolicyInfoComponentProps) => {
  const { t } = useTranslation('policy');
  const navigate = useNavigateWithReferrer();

  const labelClass =
    'flex gap-2 items-center mt-4 mb-2 text-sm leading-3 text-main-content-2';
  const headerClass = 'text-lg lg:mb-2';

  const registration =
    policyData?.detail?.policy?.policyAssets?.[0].properties?.vehicleRegistration;

  const vehicleDescription =
    policyData?.detail?.policy?.policyAssets?.[0].properties?.description;

  const policyState = policyData?.detail?.policy?.state;
  const startDate = policyData?.detail?.policy?.startDate;
  const endDate = policyData?.detail?.policy?.endDate;
  const firstName = policyData?.detail?.policy?.policyPersons?.[0].firstName;
  const lastName = policyData?.detail?.policy?.policyPersons?.[0].lastName;
  const dateOfBirth = policyData?.detail?.policy?.policyPersons?.[0].dateOfBirth;
  const drivingLicenceNumber =
    policyData?.detail?.policy?.policyPersons?.[0].drivingLicenceNumber;

  const email = policyData?.detail?.policy?.policyPersons?.[0].email;
  const mainPhoneNumber = policyData?.detail?.policy?.policyPersons?.[0].mainPhoneNumber;

  const occupation =
    policyData?.detail?.policy?.policyPersons?.[0].occupation?.description;

  const addressDisplay =
    policyData?.detail?.policy?.policyPersons?.[0].address?.displayName;

  const productName =
    policyData?.detail?.policy?.product === 'ShortTermMotor'
      ? t('policy_info.short_term_motor')
      : t('policy_info.learner_motor');

  const selectedAddOns = policyData?.detail?.policy?.selectedAddOns;
  const policyPrice = policyData?.detail?.policy?.price?.total;
  const showExtendPolicy = (canExtend && policyData?.detail?.canAmend) || false;

  const discount = getDiscount(policyData?.detail?.policy?.price);

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col gap-6 items-center w-full">
        <PolicyStateProgressBar policyData={policyData} />

        <Card paddingLevel="small" classNames="bg-content-background-prominent w-full">
          <h2 className="mb-4 text-lg lg:mb-6">{t('policy_info.vehicle')}</h2>
          <span
            className="inline-flex py-1 px-2 mb-2 font-lato text-sm leading-[120%] rounded bg-main-content-5"
            id="policy-info-registration-plate"
          >
            {registration}
          </span>
          <span
            id="policy-info-car-details"
            className="flex leading-[120%] whitespace-normal"
          >
            {vehicleDescription}
          </span>
        </Card>

        <div className="flex flex-col gap-6 w-full leading-7 sm:flex-row">
          <Card paddingLevel="small" classNames="bg-content-background-prominent w-full">
            <h2 className={headerClass}>{t('policy_info.cover_start')}</h2>

            <span className={labelClass}>
              <Icon name="date" />
              {t('policy_info.date')}
            </span>
            <span id="policy-info-starts-in">
              {moment.tz(startDate, 'Europe/London' || '').format('DD/MM/YYYY')}
            </span>

            <span className={labelClass}>
              <Icon name="time" />
              {t('policy_info.time')}
            </span>
            <span id="policy-info-starts-in-time">
              {moment.tz(startDate, 'Europe/London').format('HH:mm')}
            </span>
          </Card>

          <Card paddingLevel="small" classNames="bg-content-background-prominent w-full">
            <h2 className={headerClass}>{t('policy_info.cover_end')}</h2>

            <span className={labelClass}>
              <Icon name="date" />
              {t('policy_info.date')}
            </span>
            <span id="policy-info-ends-in">
              {moment.tz(endDate, 'Europe/London' || '').format('DD/MM/YYYY')}
            </span>

            <span className={labelClass}>
              <Icon name="time" />
              {t('policy_info.time')}
            </span>
            <span id="policy-info-ends-in-time">
              {moment.tz(endDate, 'Europe/London').format('HH:mm')}
            </span>
          </Card>
        </div>

        <Card paddingLevel="small" classNames="bg-content-background-prominent w-full">
          <h2 className={headerClass}>{t('policy_info.driver')}</h2>

          <span className={labelClass}>
            <Icon name="user" />
            {t('policy_info.name')}
          </span>
          <span id="policy-info-driver-name">{`${firstName} ${lastName}`}</span>

          <span className={labelClass}>
            <Icon name="date" />
            {t('policy_info.dob')}
          </span>
          <span id="policy-info-driver-dob">
            {moment(dateOfBirth || '').format('DD/MM/YYYY')}
          </span>

          <span className={labelClass}>
            <Icon name="card" />
            {t('policy_info.licence')}
          </span>
          <span id="policy-info-driver-licence">{drivingLicenceNumber}</span>

          <span className={labelClass}>
            <Icon name="briefcase" />
            {t('policy_info.occupation')}
          </span>
          <span id="policy-info-driver-occupation">{occupation}</span>
        </Card>

        <Card paddingLevel="small" classNames="bg-content-background-prominent w-full">
          <h2 className={headerClass}>{t('policy_info.contact_details')}</h2>

          <span className={labelClass}>
            <Icon name="email" />
            {t('policy_info.email')}
          </span>
          <span id="policy-info-email-address">{email}</span>

          {mainPhoneNumber && (
            <>
              <span className={labelClass}>
                <Icon name="mobile" />
                {t('policy_info.contact')}
              </span>
              <span id="policy-info-contact-number">{mainPhoneNumber}</span>
            </>
          )}

          <span className={labelClass}>
            <Icon name="location" />
            {t('policy_info.address')}
          </span>
          <span>{addressDisplay}</span>
        </Card>

        <Card paddingLevel="small" classNames="bg-content-background-prominent w-full">
          <h2 className={headerClass}>{t('policy_info.cover')}</h2>

          <span className={labelClass}>
            <Icon name="shield" />
            {t('policy_info.cover_type')}
          </span>
          <span id="policy-info-cover-type">{t('policy_info.comprehensive')}</span>

          <span className={labelClass}>
            <Icon name="policies" />
            {t('policy_info.product')}
          </span>
          <span id="policy-info-product">{productName}</span>

          <span className={labelClass}>
            <Icon name="medal" />
            {t('policy_info.insurer')}
          </span>
          <span id="policy-info-insurer">{policyData?.detail?.policy?.underWriter}</span>

          {selectedAddOns && selectedAddOns?.length > 0 && (
            <span className={labelClass}>
              <Icon name="dashboard" />
              {t('policy_info.add-ons')}
            </span>
          )}
          <ul className="pl-5 list-disc">
            {selectedAddOns &&
              selectedAddOns.map(addon => (
                <li key={addon.type} id={`policy-info-cover-add-ons-${addon.type}`}>
                  {getAddonTitle(t, addon)}
                </li>
              ))}
          </ul>
        </Card>
        <PolicyDocumentsList
          policyId={policyId}
          product={policyData?.detail?.policy?.product}
        />
      </div>

      <Card
        paddingLevel="small"
        classNames="flex flex-col items-center mt-8 sm:mt-12 !bg-summary-2 w-full text-white"
      >
        <span className="flex gap-2 items-center text-sm opacity-60">
          <Icon name="card" />
          {t('policy_info.total_price')}
        </span>

        {discount && (
          <span
            id="policy-info-discount"
            className={clsx('mt-1 text-base text-white opacity-60 transition-colors')}
          >
            Discount{' '}
            <FormattedPrice
              price={getDiscountValue(policyData?.detail?.policy?.price)}
              size="BASE"
              lineThrough
            />
          </span>
        )}

        <span id="policy-info-total-price" className="text-xl">
          <FormattedPrice price={policyPrice} size="XL" />
        </span>
        <span className="text-sm">
          {t('policy_info.excess', { excessAmount: policyData?.detail?.policy?.excess })}
        </span>
      </Card>

      {policyData?.detail?.policy?.underWriter &&
        policyData.detail.policy.state !== PolicyState.CANCELLED && (
          <ClaimsNumberCard
            underwriter={policyData?.detail?.policy?.underWriter}
            className="mt-12 lg:mt-16"
          />
        )}
      {policyData?.detail?.policy?.selectedAddOns?.find(
        addon =>
          addon.type === AddOnType.BREAKDOWN_ROADSIDE ||
          addon.type === AddOnType.BREAKDOWN_ONWARD_TRAVEL
      ) &&
        policyData.detail.policy.state !== PolicyState.CANCELLED && (
          <BreakdownNumberCard className="mt-8" />
        )}

      {(policyState === PolicyState.ACTIVE || policyState === PolicyState.UPCOMING) && (
        <>
          {showExtendPolicy && (
            <Button
              id="policy-info-extend-policy"
              text={t('policy_info.extend')}
              classNames="mt-12 lg:mt-16"
              onClick={() =>
                navigate({
                  pathname: `/policies/${policyId}/extend`,
                })
              }
              fullWidth
            />
          )}
          <Button
            id="policy-info-cancel-policy"
            text={t('policy_info.cancel')}
            variant="secondary"
            classNames={clsx(
              'mb-24 lg:mb-16',
              {
                'mt-16 lg:mt-12': !showExtendPolicy,
              },
              {
                'mt-8 lg:mt-8': showExtendPolicy,
              }
            )}
            onClick={() =>
              navigate({
                pathname: `/policies/${policyId}/cancel`,
              })
            }
            fullWidth
          />
        </>
      )}
    </div>
  );
};

export default PolicyInfoComponent;

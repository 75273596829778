import { useTranslation } from 'react-i18next';
import { Button, Card } from '@dayinsure/components';
import ContentContainer from '../../Layout/ContentContainer/ContentContainer';
import PageContainer from '../../Layout/PageContainer/PageContainer';
import toolbox from '../../../assets/toolbox.png';

const Help = () => {
  const { t } = useTranslation('account');

  return (
    <PageContainer>
      <ContentContainer>
        <h1 className="my-8 text-xl text-center sm:my-12">{t('help.title')}</h1>
        <img
          src={toolbox}
          alt="toolbox"
          className="object-cover mx-auto mb-4 h-[193px]"
        />
        <Card paddingLevel="small" classNames="flex items-center mt-2 mb-8 sm:mt-11">
          {t('help.card')}
        </Card>
        <a
          href="https://www.dayinsure.com/help-and-support/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            id="help-visit-faqs-link"
            text={t('help.cta')}
            classNames="mb-16 sm:mb-24"
            fullWidth
          />
        </a>
      </ContentContainer>
    </PageContainer>
  );
};

export default Help;

import Icon from '@dayinsure/components/src/components/DataDisplay/Icon/Icon';
import { useTranslation } from 'react-i18next';
import useSignIn from '../../../hooks/useSignIn';

const LoginButton = (): JSX.Element => {
  const { t } = useTranslation('header');
  const { signIn } = useSignIn();

  return (
    <button
      type="button"
      id="header-login-btn"
      className="box-border inline-flex gap-x-2 justify-center items-center px-4 h-8 font-raleway text-sm font-semibold bg-transparent rounded-lg border-2 text-header-content-1 border-header-content-1"
      onClick={() => {
        signIn();
      }}
    >
      <Icon name="user" size="0.75rem" />
      <span>{t('login')}</span>
    </button>
  );
};

export default LoginButton;

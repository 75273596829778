import * as Yup from 'yup';
import { AmendmentQuoteResponse, Duration, Price } from '../../../api';
import { PaymentFields, PaymentValidationSchema } from '../../../types/PaymentFields';

export type ExtendPolicyFormData = {
  journeyStartPosition?: boolean;
  coverLength?: Duration & { id: string };
  detailsChanged?: { id: string; name: string };
  price?: Price;
  quoteResponse?: AmendmentQuoteResponse;
  firstName?: string;
  surname?: string;
  vehicleModel?: string;
  vehicleRegistration?: string;
  excess?: number;
} & PaymentFields;

export const ExtendPolicyInitialFormData: ExtendPolicyFormData = {
  journeyStartPosition: undefined,
  coverLength: undefined,
  detailsChanged: {
    id: 'no',
    name: 'No',
  },
  price: undefined,
  quoteResponse: undefined,
  encryptedCardNumber: '',
  cardholderName: '',
  encryptedExpiryDate: '',
  encryptedExpiryMonth: '',
  encryptedExpiryYear: '',
  encryptedSecurityCode: '',
  saveCardDetails: false,
  firstName: '',
  surname: '',
  vehicleModel: '',
  vehicleRegistration: '',
  excess: undefined,
};

export const ExtendPolicyValidationSchema = Yup.object().shape({
  coverLength: Yup.object().required('Required'),
});

export enum ExtendPolicyRoutes {
  Extend = 'extend',
  Review = 'review',
  Payment = 'payment',
  PaymentProcessing = 'payment-processing',
  Success = 'success',
}

export const getExtendPolicyPageFromRoute = (route: string) => {
  if (route.endsWith(ExtendPolicyRoutes.Extend)) return ExtendPolicyRoutes.Extend;
  if (route.endsWith(ExtendPolicyRoutes.Review)) return ExtendPolicyRoutes.Review;
  if (route.endsWith(ExtendPolicyRoutes.Payment)) return ExtendPolicyRoutes.Payment;
  if (route.endsWith(ExtendPolicyRoutes.PaymentProcessing))
    return ExtendPolicyRoutes.PaymentProcessing;
  if (route.endsWith(ExtendPolicyRoutes.Success)) return ExtendPolicyRoutes.Success;
  return undefined;
};

export const getExtendPolicyValidationSchemaFromRoute = (route: string) => {
  if (route.endsWith(ExtendPolicyRoutes.Extend)) return ExtendPolicyValidationSchema;
  if (route.endsWith(ExtendPolicyRoutes.Payment)) return PaymentValidationSchema;
  return undefined;
};

export const getInitialDataFromQuote = (
  quote: AmendmentQuoteResponse
): ExtendPolicyFormData => ({
  ...ExtendPolicyInitialFormData,
  journeyStartPosition: true,
  coverLength: {
    id: `${quote.duration?.value} ${quote.duration?.type}`,
    type: quote.duration?.type,
    value: quote.duration?.value,
  },
  detailsChanged: {
    id: 'no',
    name: 'No',
  },
  price: quote.price,
  quoteResponse: quote,
  firstName: quote.persons?.[0]?.firstName || '',
  surname: quote.persons?.[0]?.lastName || '',
  vehicleModel: quote.assets?.[0]?.properties?.vehicleModel || '',
  vehicleRegistration: quote.assets?.[0]?.properties?.vehicleRegistration || '',
  excess: quote.excess || undefined,
});

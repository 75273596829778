import { ReactNode } from 'react';
import { FooterHomepageLink } from './FooterHomepageLink';
import { FooterLink } from './FooterLink';

export type FooterProps = {
  homepageLink: ReactNode;
  children: ReactNode;
  footerLegalWording: string;
};

const Footer = ({ homepageLink, children, footerLegalWording }: FooterProps) => (
  <footer
    className="flex flex-col items-center pt-12 pb-16 mt-auto w-full text-center lg:pb-[4.5rem] bg-footer-background"
    id="footer"
    data-testid="footer"
  >
    <div className="flex flex-col items-center pb-6 text-center sm:flex-row lg:pb-4">
      <div className="pb-5 sm:pb-0">{homepageLink}</div>
      <div className="flex space-x-4">{children}</div>
    </div>
    <div className="inline-flex text-center">
      <span className="px-6 font-lato text-sm text-footer-content-2">
        {footerLegalWording}
      </span>
    </div>
  </footer>
);

Footer.HomepageLink = FooterHomepageLink;
Footer.Link = FooterLink;

export { Footer };

import { Button, Card, Dialog, Icon } from '@dayinsure/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

type AutomaticLicenceDialogProps = {
  open: boolean;
  onClose: () => void;
};

const handleBackgroundClick = () => null;

const AutomaticLicenceDialog = ({ open, onClose }: AutomaticLicenceDialogProps) => {
  const { t } = useTranslation('quotes');
  return (
    <Dialog
      open={open}
      onClose={handleBackgroundClick}
      title={t('automaticLicenceDialog.title')}
    >
      <Card
        classNames="!bg-popup-background text-left font-railway text-popup-content-1 flex items-center gap-4 md:gap-6"
        paddingLevel="small"
      >
        <Icon name="warning" size="2rem" className="text-popup-content-1" />
        <p>{t('automaticLicenceDialog.p')}</p>
      </Card>

      <Button
        id="cta-automatic-licence-dialog-continue"
        fullWidth
        onClick={onClose}
        text={t('automaticLicenceDialog.cta_continue')}
        classNames="mt-6 md:mt-8"
      />
    </Dialog>
  );
};

export default AutomaticLicenceDialog;

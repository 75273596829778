type LinearProgressBarProps = {
  id: string;
  /** The progress percentage */
  progressPercentage?: number;
};

const LinearProgressBar = ({ id, progressPercentage }: LinearProgressBarProps) => {
  return (
    <div className="flex overflow-hidden relative w-full h-2 rounded-lg bg-progress-2">
      <div
        data-testid={id}
        className="absolute w-full h-2 transition-transform bg-progress-1"
        style={{ width: `${progressPercentage}%` }}
      />
    </div>
  );
};

export default LinearProgressBar;

import { RadioGroup as HeadlessRadioSelect } from '@headlessui/react';
import clsx from 'clsx';
import Icon from '../../DataDisplay/Icon/Icon';

// TODO: We need to remove ! flag from line 49 and find a way to set it without using important

export type SelectRadioOption = {
  /** ID of the radio option */
  id: string;
  /** Display name */
  name: string;
  /** Test id of the radio option */
  testId?: string;
  tooltip?: boolean;
};

/** This is just a styled radiogroup with some mandatory props */
export type RadioSelectProps = {
  /** The id of the radio group */
  id: string;
  /** The name of the radio group */
  name: string;
  /** The options available in the radiogroup */
  options: SelectRadioOption[];
  /** The current value. Must match one of the radio options. Can be undefined */
  value: SelectRadioOption | undefined;
  /** The event which will trigger when an radio option is selected */
  onChange: (option: SelectRadioOption) => void;
  /** The event which will trigger when an radio options are blurred */
  onBlur?: () => void;
  /** Error state */
  error?: boolean;
  /** Disabled state */
  disabled?: boolean;
  /**  Whether to use the primary or secondary colour scheme */
  variant?: 'primary' | 'secondary';
  /** The number of columns to split into. Will be based on breakpoint if undefined */
  cols?: {
    mobile: 1 | 2;
    tablet: 1 | 2;
    desktop: 1 | 2;
  };
  /** id used for testing */
  testId?: string;
};

const RadioSelect = ({
  id,
  name,
  options,
  value,
  onChange,
  onBlur,
  error,
  disabled,
  testId,
  variant = 'primary',
  cols,
}: RadioSelectProps) => {
  return (
    <div className="w-full" data-testid={testId}>
      <HeadlessRadioSelect
        data-testid={id}
        name={name}
        // added this logic in until the headless ui fix is in, there is an issue matching the value object to any of the options
        value={options.find(o => o.id === value?.id)}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
      >
        <div
          className={clsx(
            'grid relative gap-6',
            { 'grid-cols-1 md:grid-cols-2': !cols },
            { 'grid-cols-1': cols?.mobile === 1 },
            { 'grid-cols-2': cols?.mobile === 2 },
            { 'sm:grid-cols-1': cols?.tablet === 1 },
            { 'sm:grid-cols-2': cols?.tablet === 2 },
            { 'lg:grid-cols-1': cols?.desktop === 1 },
            { 'lg:grid-cols-2': cols?.desktop === 2 }
          )}
        >
          {options.map(option => (
            <HeadlessRadioSelect.Option
              data-testid={option.testId}
              key={option.name}
              value={option}
              className={({ checked }) =>
                clsx(
                  'gap-3 rounded-lg border-2 transition-colors selector-base',
                  {
                    'border-error-border text-main-content-1':
                      error && variant === 'primary',
                  },
                  {
                    'border-error-border text-popup-content-1':
                      error && variant === 'secondary',
                  },
                  {
                    'border-main-content-3 text-main-content-1':
                      !checked && !error && variant === 'primary',
                  },
                  {
                    'border-popup-selector-border text-popup text-popup-content-1':
                      !checked && !error && variant === 'secondary',
                  },
                  {
                    'bg-selector-active border-selector-border-1 text-selector-active-text':
                      checked && variant === 'primary',
                  },
                  {
                    'bg-popup-selector-active border-popup-selector-active-border text-popup-selector-active-text':
                      checked && variant === 'secondary',
                  },
                  { 'opacity-40': disabled },
                  { 'cursor-pointer': !disabled }
                )
              }
            >
              {({ checked }) => (
                <>
                  <div
                    className={clsx(
                      'flex shrink-0 justify-center items-center w-6 h-6 rounded-full border-2 transition-colors',
                      { 'border-selector-border-1': variant === 'primary' && !checked },
                      {
                        'border-popup-selector-active':
                          variant === 'secondary' && !checked,
                      },
                      {
                        'bg-transparent': !checked,
                        'bg-white border-white': checked,
                      }
                    )}
                  >
                    <Icon
                      name="check"
                      className={clsx(
                        { 'text-selector-active': variant === 'primary' },
                        { 'text-popup-selector-active': variant === 'secondary' },
                        {
                          invisible: !checked,
                        }
                      )}
                    />
                  </div>

                  <HeadlessRadioSelect.Label as="p" className="font-semibold truncate">
                    {option.name}
                  </HeadlessRadioSelect.Label>

                  {option.tooltip && (
                    <button aria-label="tooltip" type="button">
                      <Icon
                        name="info"
                        className={clsx(
                          { 'text-main-content-1': !checked },
                          { 'text-selector-active-text': checked }
                        )}
                      />
                    </button>
                  )}
                </>
              )}
            </HeadlessRadioSelect.Option>
          ))}
        </div>
      </HeadlessRadioSelect>
    </div>
  );
};

export default RadioSelect;

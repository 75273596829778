import clsx from 'clsx';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import moment from 'moment';
import DatePicker, { DatePickerProps } from '../DatePicker/DatePicker';
import Button from '../Button/Button';

export type DateFieldValue = {
  day: string;
  month: string;
  year: string;
  parsedDate?: string;
};

const defaultDayDate = '01';

export type DateFieldProps = {
  /** The id of the field. Used to match with the label */
  id: string;
  /** Name of the field. Used to track within formik  */
  name: string;
  /** The configuration for the field. Day object can be excluded for just the month/year variant */
  configuration: {
    day?: {
      enabled: boolean;
      placeholder: string;
    };
    month: {
      placeholder: string;
    };
    year: {
      placeholder: string;
    };
  };
  /** The current value of the field. This is an object consisting of a day, month and year string as well as a parsed date which is undefined when the date is invalid */
  value: DateFieldValue;
  /** The event which is emitted when the value is changed */
  onChange: (value: DateFieldValue) => void;
  /** The event which is emitted when the field is blurred */
  onBlur?: () => void;
  /** Disabled state */
  disabled?: boolean;
  /** Error state */
  error?: boolean;
  /** Props for the date picker. Picker will be hidden if not specified */
  picker?: {
    props?: Omit<Partial<DatePickerProps>, 'selected' | 'onSelect' | 'mode'>;
    buttonId: string;
    buttonText: string;
  };
  /** id used for testing */
  testId?: string;
};

const DateField = forwardRef<HTMLInputElement, DateFieldProps>(
  (
    {
      id,
      testId,
      name,
      configuration,
      value,
      onChange,
      onBlur,
      disabled,
      error,
      picker,
    }: DateFieldProps,
    forwardedRef
  ): JSX.Element => {
    const dayField = useRef<HTMLInputElement>(null);
    const monthField = useRef<HTMLInputElement>(null);
    const yearField = useRef<HTMLInputElement>(null);

    // Allows the forwarded ref to use the dayField ref
    useImperativeHandle(forwardedRef, () => dayField.current as HTMLInputElement);

    const [pickerDate, setPickerDate] = useState<Date | undefined>();

    const stripChars = (str: string) => str.replace(/\D/g, '');
    const isFieldFilled = (val: string, maxLength: number) => val.length >= maxLength;

    const onBackspace =
      (callback: () => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace') {
          callback();
        }
      };

    const parseDate = (day: string, month: string, year: string) => {
      if (day && month && year && year.length >= 4) {
        const parsedDate = moment.utc({
          day: parseInt(day, 10),
          month: parseInt(month, 10) - 1,
          year: parseInt(year, 10),
        });

        if (parsedDate) {
          setPickerDate(parsedDate.utc().toDate());
          return parsedDate.utc().toISOString();
        }
      }
      return undefined;
    };

    const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = stripChars(e.target.value);
      const { maxLength } = e.target;

      if (isFieldFilled(inputValue, maxLength) && monthField.current) {
        monthField.current.focus();
      }

      onChange({
        day: inputValue,
        month: value?.month || '',
        year: value?.year || '',
        parsedDate: parseDate(inputValue, value?.month, value?.year),
      });
    };

    const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = stripChars(e.target.value);
      const { maxLength } = e.target;

      if (isFieldFilled(inputValue, maxLength) && yearField.current) {
        yearField.current.focus();
      }

      const dayValue = configuration.day?.enabled ? value?.day || '' : defaultDayDate;

      onChange({
        day: dayValue,
        month: inputValue,
        year: value?.year || '',
        parsedDate: parseDate(dayValue, inputValue, value?.year),
      });
    };

    const handleMonthClear = () => {
      if (value.month.length === 0 && dayField.current) {
        dayField.current.focus();
      }
    };

    const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = stripChars(e.target.value);

      const dayValue = configuration.day?.enabled ? value?.day || '' : defaultDayDate;

      onChange({
        day: dayValue,
        month: value?.month || '',
        year: inputValue,
        parsedDate: parseDate(dayValue, value?.month, inputValue),
      });
    };

    const handleYearClear = () => {
      if (value.year.length === 0 && monthField.current) {
        monthField.current.focus();
      }
    };

    const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
      // Don't blur when focus changes between inputs within the field
      if (onBlur && !e.currentTarget.contains(e.relatedTarget)) {
        onBlur();
      }
    };

    const handlePickerChanged = (date: Date | undefined) => {
      setPickerDate(date);
      if (date) {
        onChange({
          day: date.getDate().toString(),
          month: (date.getMonth() + 1).toString(),
          year: date.getFullYear().toString(),
          parsedDate: parseDate(
            date.getDate().toString(),
            (date.getMonth() + 1).toString(),
            date.getFullYear().toString()
          ),
        });
      }
    };

    const inputClasses = clsx(
      'flex justify-evenly w-full h-6 text-center bg-transparent focus:outline-none lg:h-8 text-main-content-1 placeholder:text-main-content-2'
    );

    return (
      <div data-testid={testId} className="grid grid-cols-1 gap-6 sm:flex">
        <div
          className={clsx(
            'rounded-lg border-2  transition-colors children:focus-within:border-main-content-3 focus-within:border-main-content-1 field-base',
            { 'border-main-content-3': !error },
            { 'opacity-40': disabled },
            { 'border-error-border': error }
          )}
          onBlur={handleBlur}
        >
          {configuration.day?.enabled && (
            <input
              ref={dayField}
              data-testid={`${id}-day-input`}
              id={`${id}-day-input`}
              name={`${name}-day`}
              type="text"
              pattern="\d*"
              placeholder={configuration.day.placeholder}
              maxLength={2}
              value={value?.day || ''}
              onChange={handleDayChange}
              className={clsx(inputClasses, 'border-r border-main-content-4')}
              disabled={disabled}
            />
          )}
          <input
            ref={monthField}
            data-testid={`${id}-month-input`}
            id={`${id}-month-input`}
            name={`${name}-month`}
            type="text"
            pattern="\d*"
            placeholder={configuration.month.placeholder}
            maxLength={2}
            value={value?.month || ''}
            onChange={handleMonthChange}
            onKeyDown={onBackspace(handleMonthClear)}
            className={clsx(inputClasses, 'border-r border-main-content-4', {
              'border-l': configuration.day?.enabled,
            })}
            disabled={disabled}
          />
          <input
            ref={yearField}
            data-testid={`${id}-year-input`}
            id={`${id}-year-input`}
            name={`${name}-year`}
            type="text"
            pattern="\d*"
            placeholder={configuration.year.placeholder}
            maxLength={4}
            value={value?.year || ''}
            onChange={handleYearChange}
            onKeyDown={onBackspace(handleYearClear)}
            className={clsx(inputClasses, 'border-l border-main-content-4')}
            disabled={disabled}
          />
        </div>
        {picker && (
          <div className="shrink-0">
            <DatePicker
              {...picker.props}
              mode="single"
              selected={pickerDate}
              onSelect={handlePickerChanged}
              closeOnClick
              button={
                <Button
                  id={picker.buttonId}
                  variant="secondary"
                  fullWidth
                  text={picker.buttonText}
                  icon={{ name: 'date', position: 'left' }}
                />
              }
            />
          </div>
        )}
      </div>
    );
  }
);

export default DateField;

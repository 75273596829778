import { useMutation, useQueryClient } from 'react-query';
import {
  DayInsureAPI,
  DocumentType,
  GetPolicyDocumentsResponseIListIAPIResult,
  OpenAPI,
} from '../../api';
import query from '../queries/queryKeys';

type DocumentMutationType = {
  policyId: string;
  type: DocumentType;
};
const useSendDocumentMutation = () => {
  const queryClient = useQueryClient();

  const sendDocument = ({ policyId, type }: DocumentMutationType) => {
    const api = new DayInsureAPI(OpenAPI);

    return api.policy.postApiV1PolicyDocumentSendPaperCopy(policyId, type);
  };

  return useMutation(sendDocument, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData<GetPolicyDocumentsResponseIListIAPIResult>(
        query.policyDocsList(variables.policyId),
        docsList => {
          const newDocs = docsList?.detail?.map(doc => {
            if (doc.type === variables.type) {
              return { ...doc, sentByPost: true };
            }
            return doc;
          });
          return {
            ...docsList,
            detail: newDocs,
          };
        }
      );
    },
  });
};

export default useSendDocumentMutation;

// eslint-disable-next-line import/prefer-default-export
import commonEN from './en/common.json';
import headerEN from './en/header.json';
import footerEN from './en/footer.json';
import quotesEN from './en/quotes.json';
import dashboardEN from './en/dashboard.json';
import authEN from './en/auth.json';
import accountEN from './en/account.json';
import policyEN from './en/policy.json';
import retailEN from './en/retail.json';

export const defaultNS = 'common';
export const resources = {
  en: {
    common: commonEN,
    header: headerEN,
    footer: footerEN,
    quotes: quotesEN,
    dashboard: dashboardEN,
    auth: authEN,
    account: accountEN,
    policy: policyEN,
    retail: retailEN,
  },
};

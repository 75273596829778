import clsx from 'clsx';
import { MouseEventHandler, forwardRef } from 'react';
import Icon, { IconNames } from '../../DataDisplay/Icon/Icon';
import LoadingSpinner from '../../Feedback/LoadingSpinner/LoadingSpinner';

interface ButtonStyles {
  /** The button variant */
  variant?: 'primary' | 'secondary' | 'dialogSecondary' | 'bordered' | 'ghost';
  /** Makes the button fill the full width of its parent */
  fullWidth?: boolean;
  /** Determines the vertical height of th e button */
  size?: 'regular' | 'mini';
}

export type ButtonProps = ButtonStyles & {
  /** ID of the button field */
  id: string;
  /** The text displayed on the button */
  text?: string;
  /** The icon which will be displayed on the button */
  icon?: {
    name: IconNames;
    position?: 'left' | 'right';
    size?: string;
  };
  /** The event which will trigger when the button is activated */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** Determines whethet the button is disabled */
  disabled?: boolean;
  /** Whether the button should show a loading spinner */
  loading?: boolean;
  /** Sets the type of the button to submit, so that it can be used to submit a form */
  submit?: boolean;
  /** Set of styling classes */
  classNames?: string;
  /** Id to use in tests */
  testId?: string;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      text,
      icon,
      onClick,
      testId,
      disabled = false,
      loading = false,
      submit = false,
      variant = 'primary',
      fullWidth = false,
      size = 'regular',
      classNames,
    },
    ref
  ): JSX.Element => {
    return (
      <button
        data-testid={testId}
        id={id}
        type={submit ? 'submit' : 'button'}
        className={clsx(
          'inline-flex gap-x-3 justify-center items-center font-semibold rounded-lg',
          {
            'bg-cta-primary text-cta-primary-text': variant === 'primary',
          },
          {
            'hover:bg-cta-primary-hover': variant === 'primary' && !loading && !disabled,
          },
          {
            'box-border border-2  bg-cta-secondary border-cta-secondary-border text-cta-secondary-text':
              variant === 'secondary',
          },
          {
            'box-border border-2 bg-cta-secondary/10 border-popup-secondary-cta-border text-popup-secondary-cta-text':
              variant === 'dialogSecondary',
          },
          {
            'hover:bg-cta-secondary-hover':
              variant === 'secondary' && !loading && !disabled,
          },
          {
            'py-2 px-4 text-sm !rounded-md border-2 text-cta-secondary-text':
              variant === 'bordered',
          },
          { 'w-full': fullWidth },
          {
            'px-6 h-14 lg:px-8 lg:h-16':
              size === 'regular' && variant !== 'bordered' && variant !== 'ghost',
          },
          { 'px-4 h-8 text-sm': size === 'mini' },
          { 'opacity-40': disabled },
          classNames
        )}
        onClick={onClick}
        disabled={disabled || loading}
        ref={ref}
      >
        {loading && (
          <LoadingSpinner
            data-testid={`${testId}_loading-spinner`}
            size="small"
            className={clsx('absolute', {
              'text-popup-secondary-cta-border': variant === 'dialogSecondary',
            })}
            variant="cta"
            secondary={variant === 'secondary'}
          />
        )}
        {icon && icon.position === 'left' && (
          <Icon
            data-testid={`${testId}_icon-left`}
            name={icon.name}
            size={icon.size}
            className={clsx({ invisible: loading })}
          />
        )}
        <span className={clsx({ invisible: loading })}>{text}</span>
        {icon && icon.position === 'right' && (
          <Icon
            data-testid={`${testId}_icon-right`}
            name={icon.name}
            size={icon.size}
            className={clsx({ invisible: loading })}
          />
        )}
      </button>
    );
  }
);

export default Button;

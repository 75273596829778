import { useMemo } from 'react';

export const DOTS = '...';

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

type UsePaginationProps = {
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
};

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}: UsePaginationProps) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /* If the number of pages is less than the page numbers we want to show in our paginationComponent, we return the range [1..totalPageCount] */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /* Calculate left and right sibling index and make sure they are within range 1 and totalPageCount */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    /* We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex <= totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /* User is at the start of the paged results -No left dots to show, but rights dots to be shown */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 2 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /* User is at the end of the paged results -  No right dots to show, but left dots to be shown */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 2 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /* User is on middle of paged results - Dots to the  left and right to be shown */
    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    /* This return should not ever be reached */
    return [];
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

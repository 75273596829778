/* eslint-disable react/no-array-index-key */
import { Menu as HeadlessMenu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import clsx from 'clsx';

type MenuProps = {
  /** The trigger which opens the menu. Should be a button, but could in theory be any component with an onClick and a ref */
  trigger: JSX.Element;
  /** The content to pass into each menu button. These could be buttons or Router links, but should generally be interactive. Classes will need to be applied for spacing */
  children: React.ReactNode;
  /** Orientation of the menu. Opens to the left or the right */
  position: 'left' | 'right' | 'center';
};

const Menu = ({ children, trigger, position = 'center' }: MenuProps) => {
  return (
    <HeadlessMenu as="div" className="inline-block relative">
      <HeadlessMenu.Button as={Fragment}>{trigger}</HeadlessMenu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <HeadlessMenu.Items
          className={clsx(
            'overflow-hidden absolute z-30 mt-3 w-max rounded-lg opacity-0 scale-95 origin-top-right bg-menu-background',
            { 'right-0': position === 'left' },
            { 'right-1/2 translate-x-1/2': position === 'center' }
          )}
        >
          {children}
        </HeadlessMenu.Items>
      </Transition>
    </HeadlessMenu>
  );
};

Menu.Item = HeadlessMenu.Item;

export default Menu;

import { FieldError, Label } from '@dayinsure/components';
import { IconNames } from '@dayinsure/components/src/components/DataDisplay/Icon/Icon';
import PasswordField, {
  PasswordFieldProps,
} from '@dayinsure/components/src/components/Inputs/PasswordField/PasswordField';
import clsx from 'clsx';
import { useField } from 'formik';

type FormPasswordFieldProps = {
  label: {
    icon: IconNames;
    text: string;
  };
  withErrors?: boolean;
  spacingClassName?: string;
} & Omit<PasswordFieldProps, 'label' | 'error' | 'onChange'>;

const FormPasswordField = ({
  label,
  withErrors = true,
  spacingClassName,
  ...passwordProps
}: FormPasswordFieldProps) => {
  const [field, meta] = useField(passwordProps);

  return (
    <div
      className={clsx(
        'relative',
        { 'form-field-spacing': !spacingClassName },
        spacingClassName
      )}
    >
      <div className="flex space-x-2">
        <Label
          name={label.text}
          icon={label.icon}
          htmlFor={passwordProps.id}
          disabled={passwordProps.disabled}
          className={clsx('mb-4', {
            'text-popup-content-1': passwordProps.variant === 'dialog',
          })}
        />
      </div>
      <PasswordField
        {...passwordProps}
        {...field}
        error={withErrors && meta.touched && meta.error !== undefined}
        aria-errormessage={`${passwordProps.id}-error`}
      />
      {withErrors && meta.touched && meta.error && (
        <FieldError
          id={`${passwordProps.id}-error`}
          error={meta.error}
          className="absolute right-0 mt-2"
        />
      )}
    </div>
  );
};

export default FormPasswordField;

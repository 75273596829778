import { useSearchParams } from 'react-router-dom';

const useSearchParamsToBuildPermittedQueryParams = () => {
  const [params] = useSearchParams();
  const permittedParams: Array<string> = [
    'referrer',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'referrerChannel',
  ];
  let query = '';
  permittedParams.forEach(function (permittedParamName) {
    if (params.get(permittedParamName)) {
      const delimeter = query === '' ? '' : '&';
      query = query.concat(
        delimeter,
        permittedParamName,
        '=',
        params.get(permittedParamName)!
      );
    }
  });
  return query;
};

export default useSearchParamsToBuildPermittedQueryParams;

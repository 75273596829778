import clsx from 'clsx';
import { forwardRef } from 'react';

/** This is just a styled input with some mandatory props */
export type TextFieldProps = {
  /** ID of the input field. Used to match with label */
  id: string;
  /** Name of the  field. Used to track within formik  */
  name: string;
  /** Placehol der to display when the field  is empty */
  placeholder: string;
  /** The current value of the field */
  value?: string;
  /** The event whic h is emitted when the value is changed */
  onChange: (value: string) => void;
  /** Disabled state */
  disabled?: boolean;
  /** Error state */
  error?: boolean;
  /** Id used for testing */
  testId?: string;
} & React.HTMLProps<HTMLInputElement>;

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      id,
      name,
      placeholder,
      value,
      onChange,
      disabled,
      error,
      type = 'text',
      testId,
      ...rest
    }: TextFieldProps,
    ref
  ): JSX.Element => {
    return (
      <input
        id={id}
        data-testid={testId}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        aria-invalid={error}
        {...rest}
        className={clsx(
          'bg-transparent rounded-lg border-2 focus:outline-none transition-colors focus:border-main-content-1 text-main-content-1 placeholder:text-main-content-2 field-base',
          { 'opacity-40': disabled },
          { 'border-main-content-3': !error },
          { 'border-error-border': error },
          rest.className
        )}
        ref={ref}
      />
    );
  }
);

export default TextField;

import clsx from 'clsx';
import CheckboxSelector from './CheckboxSelector';

type CheckboxOption = {
  /** ID of the radio option */
  id: string;
  /** Display name */
  name: string;
  /** Whether the checkbox is selected or not */
  checked: boolean;
  /** price to be displayed in checkbox */
  price?: number;
  /** price prefix will be displayed before the price element */
  pricePrefix?: string;
  /** price sufix will be displayed after the price element */
  priceSufix?: string;
};

/** This is a styled checkbox group */
export type CheckboxSelectProps = {
  /** The id of the checkbox group */
  id: string;
  /** Name of the checkbox group. Used to link up formik */
  name: string;
  /** The options available in the checkbox group */
  value: CheckboxOption[];
  /** The event which will trigger when an checkbox option is selected, return all options  */
  onChange?: (values: CheckboxOption[]) => void;
  /** The event which will trigger when an checkbox option is selected, return selected option */
  onClick?: (value: CheckboxOption) => void;
  /** The event which will trigger when the checkboxes are blurred */
  onBlur?: () => void;
  /** Error state */
  error?: boolean;
  /** Disabled state */
  disabled?: boolean;
  /** The number of columns to split into. Will be based on breakpoint if undefined */
  cols?: {
    mobile: 1 | 2;
    tablet: 1 | 2;
    desktop: 1 | 2;
  };
  tooltip?: {
    ariaLabel: string;
    triggerOnSelectorClick?: boolean;
    onClick: (id: string, name: string, checked: boolean) => void;
  };
  /** Id used for testing */
  testId?: string;
};

const CheckboxSelect = ({
  id,
  testId,
  name,
  value,
  onChange,
  onClick,
  onBlur,
  error,
  disabled,
  cols,
  tooltip,
}: CheckboxSelectProps) => {
  const handleChange = (option: CheckboxOption) => {
    if (onBlur) {
      onBlur();
    }

    if (onChange) {
      onChange(
        value.map(val => {
          if (val.id === option.id) {
            return {
              id: val.id,
              name: val.name,
              checked: option.checked,
              price: val.price,
              pricePrefix: val.pricePrefix,
              priceSufix: val.priceSufix,
            };
          }
          return val;
        })
      );
    }

    if (onClick) {
      onClick(option);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    // Don't blur when focus changes between checkboxes
    if (onBlur && !e.currentTarget.contains(e.relatedTarget)) {
      onBlur();
    }
  };

  return (
    <div
      className={clsx(
        'grid relative gap-6',
        { 'grid-cols-1 md:grid-cols-2': !cols },
        { 'grid-cols-1': cols?.mobile === 1 },
        { 'grid-cols-2': cols?.mobile === 2 },
        { 'sm:grid-cols-1': cols?.tablet === 1 },
        { 'sm:grid-cols-2': cols?.tablet === 2 },
        { 'lg:grid-cols-1': cols?.desktop === 1 },
        { 'lg:grid-cols-2': cols?.desktop === 2 }
      )}
      onBlur={handleBlur}
      data-testid={testId}
    >
      {value.map(val => (
        <CheckboxSelector
          key={val.id}
          id={val.id}
          data-testid={`${id}-${val.id}`}
          name={`${name}-${val.name}`}
          displayText={val.name}
          isChecked={val.checked}
          onChange={handleChange}
          error={error}
          disabled={disabled}
          price={val.price}
          priceSufix={val.priceSufix}
          pricePrefix={val.pricePrefix}
          tooltip={tooltip}
        />
      ))}
    </div>
  );
};

export default CheckboxSelect;

import { Button, Card, Dialog, RadioSelect } from '@dayinsure/components';
import React, { useState } from 'react';
import { useTranslation, TFunction } from 'react-i18next';

type ConvertedVanDialogProps = {
  open: boolean;
  onClose: (isConverted: boolean) => void;
};

type Option = {
  id: string;
  name: string;
  testId?: string;
};

enum OptionId {
  YES = 'YES',
  NO = 'NO',
}

const getOptions = (t: TFunction<'quotes'>): Option[] => [
  {
    id: OptionId.YES,
    name: t('converted_van_dialog.option_yes'),
    testId: 'option_yes',
  },
  {
    id: OptionId.NO,
    name: t('converted_van_dialog.option_no'),
    testId: 'option_no',
  },
];

const ConvertedVanDialog = ({ open, onClose }: ConvertedVanDialogProps) => {
  const { t } = useTranslation('quotes');
  const [selectedOption, setOption] = useState<Option>();

  const handleCloseDialog = (): void => {
    if (selectedOption?.id === OptionId.YES) {
      return onClose(true);
    }

    return onClose(false);
  };

  const handleChangeOption = (option: Option) => setOption(option);

  const handlePreventCloseModalWithoutSelectedOption = () => null;

  return (
    <Dialog
      open={open}
      onClose={handlePreventCloseModalWithoutSelectedOption}
      title={t('converted_van_dialog.title')}
    >
      <Card
        classNames="!bg-popup-background text-left font-railway text-popup-content-1 mb-8"
        paddingLevel="small"
      >
        <p>{t('converted_van_dialog.p')}</p>
      </Card>
      <RadioSelect
        id="vanConvertedToMotorhome"
        name="vanConvertedToMotorhome"
        options={getOptions(t)}
        value={selectedOption}
        onChange={handleChangeOption}
        variant="secondary"
      />

      <Button
        id="cta-mulsanne-dialog-continue"
        fullWidth
        onClick={handleCloseDialog}
        text={t('converted_van_dialog.cta_select')}
        classNames="mt-6 md:mt-8"
        disabled={!selectedOption}
      />
    </Dialog>
  );
};

export default ConvertedVanDialog;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { defaultNS, resources } from './localisation';

/** Given that only English language is supported we are keeping the copy within the src folder, 
rather than fetching it via a backend. If the site is ever expanded to support new languages
we would suggest something like i18next-http-backend + i18next-browser-languagedetector to 
fetch the correct translations. */

i18n.use(initReactI18next).init({
  resources,
  defaultNS,
  lng: 'en',
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'development' && true,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;

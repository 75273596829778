import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import clsx from 'clsx';
import AccountDrawer from './AccountDrawer/AccountDrawer';
import AccountMenu from './AccountMenu/AccountMenu';
import LoginButton from './LoginButton/LoginButton';
import MenuDrawer from './MenuDrawer/MenuDrawer';
import NavLink from './NavLink/NavLink';
import NewQuoteButton from './NewQuoteButton/NewQuoteButton';
import useReferrer from '../../hooks/useReferrer';
import PageContainer from '../Layout/PageContainer/PageContainer';
import useNavigateWithReferrer from '../../hooks/useNavigateWithReferrer';
import usePerson from '../../hooks/usePerson';

const Header = (): JSX.Element => {
  const { t } = useTranslation('header');
  const { removeUser } = useAuth();
  const { isFullAccount, isGuest } = usePerson();
  const referrer = useReferrer();
  const navigate = useNavigateWithReferrer();

  const [isLoginPage, setIsLoginPage] = useState(false);

  const currentRoute = useLocation();
  useEffect(() => {
    const loginFlowRoutes = [
      '/forgotten-password',
      '/forgotten-password/reset',
      '/sign-up',
      '/sign-up/verify',
    ];
    if (loginFlowRoutes.includes(currentRoute.pathname)) {
      setIsLoginPage(true);
    } else {
      setIsLoginPage(false);
    }
  }, [currentRoute]);

  return (
    <header className="bg-header-background">
      <PageContainer className="flex items-center py-6 w-full h-16 lg:h-[4.5rem] justify-left">
        <>
          <button
            type="button"
            onClick={() => {
              if (isGuest) removeUser(); // If the user clicks home whilst logged in as guest, wipe their login
              navigate(referrer.homeUrl);
            }}
          >
            <img
              id="header-dayinsure-logo"
              className={clsx({
                'h-4 xs:h-6': referrer.logoSize === 'regular',
                'h-6 xs:h-8': referrer.logoSize === 'large',
              })}
              src={referrer.logo}
              alt={t('logo')}
            />
          </button>

          {isFullAccount && (
            <nav className="ml-auto">
              <div className="inline-flex gap-x-8 justify-center items-center md:hidden">
                <MenuDrawer />
                <AccountDrawer />
              </div>
              <div className="hidden gap-x-12 justify-center items-center md:inline-flex">
                <NavLink
                  id="header-dashboard-btn"
                  linkText={t('dashboard')}
                  to="/dashboard"
                  icon={{ name: 'dashboard' }}
                />
                <NavLink
                  id="header-policies-btn"
                  linkText={t('policies')}
                  to="/policies"
                  icon={{ name: 'dpolicies' }}
                />
                <NavLink
                  id="header-help-btn"
                  linkText={t('help')}
                  to="/help"
                  icon={{ name: 'help' }}
                />
                <AccountMenu />
              </div>
            </nav>
          )}
          {!isFullAccount && (
            <>
              {referrer.customerAccessEnabled && (
                <div className="ml-auto">
                  {isLoginPage ? <NewQuoteButton /> : <LoginButton />}
                </div>
              )}
            </>
          )}
        </>
      </PageContainer>
    </header>
  );
};

export default Header;

import { ReactNode } from 'react';

export type ResponsiveNavigationProps = {
  desktopContent: ReactNode;
  mobileContent: ReactNode;
};

export const ResponsiveNavigation = ({
  desktopContent,
  mobileContent,
}: ResponsiveNavigationProps) => (
  <>
    <div className="inline-flex gap-x-8 justify-center items-center md:hidden">
      {mobileContent}
    </div>
    <div className="hidden gap-x-12 justify-center items-center md:inline-flex">
      {desktopContent}
    </div>
  </>
);

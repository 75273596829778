import { ReactNode } from 'react';
import { LogoButton } from './LogoButton';
import { ResponsiveNavigation } from './ResponsiveNavigation';
import { ResponsiveContainer } from './ResponsiveContainer';

export type HeaderProps = {
  logo: ReactNode;
  children: ReactNode;
};

const Header = ({ logo, children }: HeaderProps) => (
  <header className="bg-header-background">
    <ResponsiveContainer className="flex items-center py-6 w-full h-16 lg:h-[4.5rem] justify-left">
      <>
        {logo}
        <nav className="ml-auto">{children}</nav>
      </>
    </ResponsiveContainer>
  </header>
);

Header.Logo = LogoButton;
Header.ResponsiveNavigation = ResponsiveNavigation;

export { Header };
